* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #ffffff;
  font-size: 2vh;
  flex-wrap: wrap;
}

p {
  font-size: 2.5vh;
}

h3 {
  font-size: 3vh;
  color: #70a37f;
}

.body {
  background-color: #2b2b2b;
  height: 100vh;
}

.header-nav-bar {
  align-items: center;
  background-color: #2b2b2b;
  border-bottom: 2px solid #3d3c3c;
  color: #ffffff;
  display: flex;
  justify-content: center;
  height: 10%;
  padding: 0 16px;
  text-align: center;
}

.app-header-nav-bar {
  align-items: center;
  background-color: #2b2b2b;
  border-bottom: 2px solid #3d3c3c;
  color: #ffffff;
  display: flex;
  height: 10%;
  padding: 0 16px;
}

.back-btn {
  color: #13293d;
  border: none;
  width: 20%;
  padding-right: 5%;
  z-index: 100;
  font-size: 2.5vh;
}

.backButtonClass {
  font-size: 3vh;
}

.links-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10%;
  padding: 20px;
  background-color: #2b2b2b;
  font-size: 2vh;
  border-bottom: 2px solid #3d3c3c;
}

.links-grid-container {
  display: inline-block;
}

.link-grid-item {
  display: flex;
  gap: 8vh;
}

.inline-link {
  padding: 1vh;
  text-align: center;
  font-size: 3.2vh;
  background-color: #70a37f;
  right: 10px;
  top: 16%;
  border-radius: 15px;
}

.grid_body {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.icon-grid-container {
  padding-top: 1vh;
  border-top: 1px solid #13293d;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2vh;
}

.grid-item {
  /* icons */
  display: flex;
  justify-content: center;
  border-radius: 8px;
  text-align: center;
}

.logoClass {
  height: 12vh;
}

.appTitle {
  text-align: center;
  font-size: 3.5vh;
  color: #ffffff;
  padding: 5%;
  width: 70%;
}

.homeAppTitle {
  text-align: center;
  font-size: 5vh;
  color: #ffffff;
  padding: 5%;
  width: 70%;
}

.appInfo1 {
  padding: 0.2vh;
  text-align: left;
  font-size: 4vh;
  color: #13293d;
}

.inTextImg {
  width: 100%;
}

.accordion {
  align-items: center;
  background-color: #2b2b2b;
}

.accordionTitle {
  height: 12vh;
  color: #2b2b2b;
}

.accordionItems {
  font-size: 2.5vh;
  padding-bottom: 1vh;
  padding-top: 1vh;
  padding-left: 0.5vh;
  border-bottom: 0.15vh solid #13293d;
}

.appTitle-Source {
  padding-left: 10%;
  padding-right: 15%;
  font-size: 3vh;
}

.sources {
  padding: 0.2vh;
  text-align: center;
  position: fixed;
  bottom: 2vh;
  width: 100%;
}

.sources-inline-link {
  font-size: 2vh;
}

.sourcesBody {
  display: flexbox;
  width: 100%;
}

.checkbox {
  position: fixed;
  right: 16%;
  align-items: center;
}

.doneButton {
  background-color: #70a37f;
  padding: 0.2vh;
  text-align: center;
}

.link-grid-item {
  align-items: center;
}

.links-grid-container {
  align-items: center;
}

.infoBody {
  background-color: #444444;
  color: #ffffff;
  font-size: 3vh;
}

.infoSubtitleCentre {
  justify-content: center;
  text-align: center;
  width: 100%;
  padding-top: 1vh;
}

.infoParagraph {
  text-align: center;
  padding-bottom: 1vh;
  border-bottom: 0.2vh solid #2b2b2b;
}
